var Consulta = {

    //Enviar consulta
    sendConsulta: function () {
        var valores = {
            nombre_y_apellido: $('#nombre').val(),
            email: $('#email').val(),
            telefono: $('#telefono').val(),
            comentarios: $('#comentarios').val(),
        };

        if (!valores.nombre_y_apellido) {
            alert('Complete su nombre y apellido');
            return false;
        }

        if (!valores.email) {
            alert('Complete su email');
            return false;
        }

        if (!valores.telefono) {
            alert('Complete su telefono');
            return false;
        }


        var boton = $('#send-consulta');
        var actual = boton.html();
        var formulario = $('#subscribeForm');
        var mensaje = $('#mensaje');

        $.ajax({
            url: 'https://panel.cloudcrm.com.ar/consultasLanding',
            method: 'POST',
            data: valores,
            beforeSend: function () {
                boton.html('<i class="fa fa-spinner fa-spin"></i> Enviando').attr('disabled', true);
                //mensaje.html('<i class="fa fa-spinner fa-spin"></i> Enviando');
            },
            success: function (res) {
                boton.html(actual).attr('disabled', false);
                console.log(res);
                if (res == 1) {
                    mensaje.html('Solicitud enviada correctamente').addClass('alert').addClass('alert-success');
                    formulario.find(':text').val('');
                    formulario.find('textarea').val('');
                    formulario.find('input[type="email"]').val('');
                    formulario.find('input').val('');
                } else {
                    alert(res);
                }
            },
            error: function (res) {
                boton.html(actual).attr('disabled', false);
                console.log(res);
            }
        })
    },

    sendRegistro: function () {
        var form = $('.registro');
        var dataSend = form.serializeArray();
        var actual = form.find('button').html();

        $.ajax({
            url: form.attr('action'),
            method: 'POST',
            data: dataSend,
            //contentType: "application/json",
            beforeSend: function () {
                form.find('button').html('<i class="fa fa-spin fa-spinner"></i> Enviando').attr('disabled', true);
            },
            success: function (res) {
                form.find('button').html(actual).attr('disabled', false);
                console.log(res);

                if (res == 'ok' || res == 'Su registro se encuentra incompleto') {
                    window.location.href = '/pagar'
                } else {
                    $('#mensaje').html(res);
                }
            },
            error: function (res) {
                form.find('button').html(actual).attr('disabled', false);
                console.log(res)
            }
        })
    },
    
    sendRegistroDemo: function () {
        var form = $('.registro');
        var dataSend = form.serializeArray();
        var actual = form.find('button').html();
        
        $.ajax({
            url: form.attr('action'),
            method: 'POST',
            data: dataSend,
            //contentType: "application/json",
            beforeSend: function () {
                form.find('button').html('<i class="fa fa-spin fa-spinner"></i> Enviando').attr('disabled', true);
            },
            success: function (res) {
                form.find('button').html(actual).attr('disabled', false);
                console.log(res);
                
                if (res == 'ok' || res == 'Su registro se encuentra incompleto') {
                    window.location.href = '/gracias'
                } else {
                    $('#mensaje').html(res);
                }
            },
            error: function (res) {
                form.find('button').html(actual).attr('disabled', false);
                console.log(res)
            }
        })
    },
    
    sendSuscripcion: function () {
        var form = $('#subscribeForm');
        var boton = form.find('button');
        var actual = boton.html();
        $.ajax({
            url: '/actions/suscripcion',
            method: 'POST',
            data: {
                tipo: 'newsletter',
                email: form.find('#email').val(),
            },
            beforeSend: function () {
                boton.html('<i class="fa fa-spinner fa-spin"></i> Enviando').attr('disabled', true);
            },
            success: function (res) {
                console.log(res);

                boton.html(actual).attr('disabled', false);
                $('#newsletter-success').html(res);

                setTimeout(function () {
                    $('#newsletter-success').html('');
                    if (res === 'Gracias por su suscripcion.') {
                        form.find('#email').val('')
                    }
                }, 2500)
            },
            error: function (res) {
                console.log(res);
                boton.html(actual).attr('disabled', false);
                $('#newsletter-error').html(res);
                setTimeout(function () {
                    $('#newsletter-error').html('');
                }, 2500)
            }
        })
    },

    loadPlanes: function () {
        var planes = $('#planes');
        $.ajax({
            url: '/planes.json',
            method: 'POST',
            data: {},
            beforeSend: function (res) {
                planes.html('<i class="fa fa-spin fa-refresh"></i> Cargando planes');
            },
            success: function (res) {
                var e = eval(res);
                console.log(res);
                var rend = '';
                for (var i = 0; i < e.length; i++) {
                    var item = e[i];

                    rend += '' +
                        '<div class="col-md-4 col-sm-4 col-xs-6">' +
                        '<div class="package package-free">' +
                        '<h3 class="pack-title">' + item.nombre + '</h3>' +
                        '<p class="pack-price"><span class="currency">$</span>' + item.valor + '<span class="pack-duration">/mes</span></p>' +
                        '' +
                        '<ul class="pack-features">' +
                        '<li><b>Clientes:</b> ' + item.max_clientes + '</li>' +
                        '<li><b>Sucursales:</b> ' + item.max_sucursales + '</li>' +
                        '<li><b>Usuarios:</b> ' + item.max_usuarios + '</li>' +
                        '</ul>' +
                        '' +
                        '<a class="order-btn" href="/registro/' + item.id + '"><i class="fa fa-check"></i> Contratar</a>' +
                        '</div>' +
                        '</div>'
                }
                planes.html(rend);


            },
            error: function (res) {
                console.log('Error');
                console.log(res);
            }
        });
    }
};