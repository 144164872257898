$(document).ready(function () {
  'use strict';

  /*-------------------------------------
   Registro de nuevo usuario
   -------------------------------------*/
  $('.registro').find('#registrarse').on('click', function () {
    Consulta.sendRegistro();
  });
  
  /*-------------------------------------
   Enviar una consulta
   -------------------------------------*/
  $('#emailsubmit').on('click', function () {
    Consulta.sendSuscripcion();
  });
  
  /*-------------------------------------
   Registro de demo
   -------------------------------------*/
  $('.registro').find('#registrarse-demo').on('click', function () {
    Consulta.sendRegistroDemo();
  });
  
  /*-------------------------------------
   Seleccionar el plan
   -------------------------------------*/
  $('.item-plan').on('click', function () {
    var id = $(this).attr('data-plan');
    var iden = $(this).attr('id');
    $('#plan').val(id);
    $('.planes').find('.item-plan').not('#' + iden).removeClass('item-active');
    $('#' + iden).addClass('item-active');
  });

  /*-------------------------------------
   One Page Nav
   -------------------------------------*/
  $('#one-page-nav').onePageNav({
    currentClass: 'active',
  });

  /*-------------------------------------
   Magnific Popup
   -------------------------------------*/
  /*
  $('.image-large').magnificPopup({
    type: 'image',
    gallery: {
      enabled: true
    }
  });
  $('.play-video').magnificPopup({
    type: 'iframe'
  });

  $.extend(true, $.magnificPopup.defaults, {
    iframe: {
      patterns: {
        youtube: {
          index: 'youtube.com/',
          id: 'v=',
          src: 'http://www.youtube.com/embed/%id%?autoplay=1'
        }
      }
    }
  });
  
  */
  /*==========================================================
   Newletter Subscribe
   ==========================================================*/
   /*
  $(".subscription").ajaxChimp({
    callback: mailchimpResponse,
    url: "http://codepassenger.us10.list-manage.com/subscribe/post?u=6b2e008d85f125cf2eb2b40e9&id=6083876991" // Replace your mailchimp post url inside double quote "".
  });

  function mailchimpResponse(resp) {
    if (resp.result === 'success') {

      $('.newsletter-success').html(resp.msg).fadeIn().delay(3000).fadeOut();

    } else if (resp.result === 'error') {
      $('.newsletter-error').html(resp.msg).fadeIn().delay(3000).fadeOut();
    }
  }
  */
  
});

//Window load event
$(window).on('load', function () {
  $("#preloader").delay(200).fadeOut();
});
